import React from "react";
import styled from "styled-components";

import LanguageProps from "../../interfaces/LanguageProps";

const StyledTableRow = styled.tr`
  border-top: 1px solid ${({ theme }) => theme.colors.darkGrey};
`;

const StyledTableCell = styled.td`
  padding: 1rem 0;
`;

const TypeTableCell = styled(StyledTableCell)`
  width: 25%;

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.tablet}) {
    width: 50%;
  }
`;

const Name = styled.p`
  color: ${({ theme }) => theme.colors.bavakRed};
`;

interface GunTableRowProps extends LanguageProps {
  type: string;
  name: string;
  distance: string;
  speed: string;
  amountOfHits: string;
  energy?: string;
}

const GunTableRow: React.FC<GunTableRowProps> = ({
  children,
  language,
  type,
  name,
  distance,
  energy,
  speed,
  amountOfHits,
}) => {
  const isEnglish = language === "en";

  return (
    <StyledTableRow>
      <TypeTableCell>{type}</TypeTableCell>
      <StyledTableCell>
        <Name>{name}</Name>
        {children}
        <p>
          {isEnglish ? "Distance" : "Afstand"}: {distance}
        </p>
        {energy && (
          <p>
            {isEnglish ? "Energy" : "Energie"}: {energy}
          </p>
        )}
        <p>
          {isEnglish ? "Speed" : "Snelheid"}: {speed}
        </p>
        <p>
          {isEnglish ? "Amount of hits" : "Aantal treffers"}: {amountOfHits}
        </p>
      </StyledTableCell>
    </StyledTableRow>
  );
};

export default GunTableRow;
