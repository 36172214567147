import React from "react";
import styled from "styled-components";

import { PaddingContainer } from "../Containers";
import { H1, H2, H3 } from "../Text";

import GunTableRow from "./GunTableRow";
import ComparisonTableRow from "./ComparisonTableRow";
import LanguageProps from "../../interfaces/LanguageProps";

const StyledTable = styled.table`
  text-align: left;
`;

const TableHeader = styled.th`
  font-weight: bold;
  padding-bottom: 0.5rem;
`;

const Information = ({ language }: LanguageProps) => {
  const isEnglish = language === "en";

  return (
    <PaddingContainer>
      <H1>{isEnglish ? "Information" : "Informatie"}</H1>
      <H2>
        {isEnglish
          ? "Standards of bullet-resistant constructions"
          : "Normen kogelwerende constructies"}
      </H2>
      <H3>
        {isEnglish
          ? "Classification according to European Standard EN 1522. Small arms and rifles:"
          : "Classificatie volgens Europese Norm EN 1522. Handvuurwapens en geweren:"}
      </H3>
      <StyledTable>
        <thead>
          <tr>
            <TableHeader>{isEnglish ? "Class" : "Klasse"}</TableHeader>
            <TableHeader>
              {isEnglish
                ? "Projectile caliber and type"
                : "Kaliber en type projectiel"}
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          <GunTableRow
            language={language}
            type="FB1"
            name=".22 Long Rifle (L.R.)"
            distance="10m"
            energy="170J"
            speed="360 m/s"
            amountOfHits="3"
          />
          <GunTableRow
            language={language}
            type="FB2"
            name="9mm Para"
            distance="10m"
            energy="170J"
            speed="360 m/s"
            amountOfHits="3"
          />
          <GunTableRow
            language={language}
            type="FB3"
            name=".357 Magnum"
            distance="10m"
            energy="170J"
            speed="360 m/s"
            amountOfHits="3"
          />
          <GunTableRow
            language={language}
            type="FB4"
            name=".44 Magnum"
            distance="10m"
            energy="170J"
            speed="360 m/s"
            amountOfHits="3"
          />
          <GunTableRow
            language={language}
            type="FB5"
            name="5.56x45 NATO"
            distance="10m"
            energy="170J"
            speed="360 m/s"
            amountOfHits="3"
          />
          <GunTableRow
            language={language}
            type="FB6"
            name="5.56x45 Armour Piercing"
            distance="10m"
            energy="170J"
            speed="360 m/s"
            amountOfHits="3"
          />
          <GunTableRow
            language={language}
            type="FB6"
            name="7.62x51 Soft Core"
            distance="10m"
            energy="170J"
            speed="360 m/s"
            amountOfHits="3"
          />
          <GunTableRow
            language={language}
            type="FB7"
            name="7.62x51 Armour Piercing"
            distance="10m"
            energy="170J"
            speed="360 m/s"
            amountOfHits="3"
          />
        </tbody>
      </StyledTable>
      <H3>
        {isEnglish
          ? "Additional categories of shotguns SG:"
          : "Bijkomende categoriën shotguns SG:"}
      </H3>
      <StyledTable>
        <thead>
          <tr>
            <TableHeader>{isEnglish ? "Class" : "Klasse"}</TableHeader>
            <TableHeader>
              {isEnglish
                ? "Projectile caliber and type"
                : "Kaliber en type projectiel"}
            </TableHeader>
          </tr>
        </thead>
        <tbody>
          <GunTableRow
            language={language}
            type="SG1"
            name="Riot Gun Cal. 12/70 Brenneke"
            distance="10m"
            speed="420 m/s"
            amountOfHits="1"
          />
          <GunTableRow
            language={language}
            type="SG2"
            name="Riot Gun Cal. 12/70 Brenneke"
            distance="10m"
            speed="420 m/s"
            amountOfHits="3"
          />
        </tbody>
      </StyledTable>
      <H3>
        {isEnglish
          ? "Comparison between the old DIN Norm 52 290 and the European Norm EN 1522:"
          : "Vergelijking tussen de oude DIN Norm 52 290 en de Europese Norm EN"}
        1522:
      </H3>
      <StyledTable>
        <thead>
          <tr>
            <TableHeader>EN 1522</TableHeader>
            <TableHeader>DIN 52 290</TableHeader>
            <TableHeader>{isEnglish ? "Caliber" : "Kaliber"}</TableHeader>
          </tr>
        </thead>
        <tbody>
          <ComparisonTableRow
            EN1522="FB2"
            DIN52290="M1"
            caliber="9mm x 19 Parabellum"
          />
          <ComparisonTableRow
            EN1522="FB3"
            DIN52290="M2"
            caliber=".357 Magnum"
          />
          <ComparisonTableRow
            EN1522="FB4"
            DIN52290="M3"
            caliber=".44 Remington Magnum"
          />
          <ComparisonTableRow
            EN1522="FB6"
            DIN52290="M4"
            caliber="7.62x51 Soft Core"
          />
          <ComparisonTableRow
            EN1522="FB7"
            DIN52290="M5"
            caliber="7.62x51 Armour Piercing"
          />
        </tbody>
      </StyledTable>
    </PaddingContainer>
  );
};

export default Information;
