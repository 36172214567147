import React from "react";
import styled from "styled-components";

const StyledTableRow = styled.tr`
  border-top: 1px solid ${({ theme }) => theme.colors.darkGrey};
`;

const StyledTableCell = styled.td`
  padding: 1rem 0;
`;

interface ComparisonTableRowProps {
  EN1522: string;
  DIN52290: string;
  caliber: string;
}

const ComparisonTableRow = ({
  EN1522,
  DIN52290,
  caliber,
}: ComparisonTableRowProps) => (
  <StyledTableRow>
    <StyledTableCell>{EN1522}</StyledTableCell>
    <StyledTableCell>{DIN52290}</StyledTableCell>
    <StyledTableCell>{caliber}</StyledTableCell>
  </StyledTableRow>
);

export default ComparisonTableRow;
