import React from "react";
import { PageProps } from "gatsby";

import SEO from "../components/SEO";
import { Information } from "../components/Information";

const EnglishInformationPage: React.FC<PageProps> = ({ location }) => (
  <>
    <SEO title="Information" lang="en" pathName={location.pathname} />
    <Information language="en" />
  </>
);

export default EnglishInformationPage;
